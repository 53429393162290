<template>
  <div>
    <div class="step">
      <center-title
        title="认证管理"
        :border="false"
        v-if="step == 0"
      ></center-title>
      <div class="step-list pr row-between lg lighter" v-if="step != 4">
        <div class="step-item column-center" :class="{ primary: step >= 1 }">
          <!-- <img src="@/assets/icon/icon-step1.png" alt="" /> -->
          <i class="iconfont">&#xe7c5;</i>
          联系人基本信息
        </div>
        <div class="step-item column-center" :class="{ primary: step >= 2 }">
          <!-- <img src="@/assets/icon/icon-step2.png" alt="" /> -->
          <i class="iconfont">&#xe792;</i>
          企业基本信息
        </div>
        <div class="step-item column-center" :class="{ primary: step >= 3 }">
          <!-- <img src="@/assets/icon/icon-step3.png" alt="" /> -->
          <i class="iconfont">&#xe77e;</i>
          证件照片
        </div>
      </div>
      <div class="error-box" v-if="status == 2 && step != 4">
        失败原因：{{ companyInfo.reject_desc }}
      </div>
      <div class="join column-center" v-if="step == 0">
        <p>实名认证管理</p>
        <button type="primary" class="row-center white lg" @click="step = 1">
          立即认证
        </button>
      </div>
    </div>
    <step-one
      v-show="step == 1"
      @submitEmit="submitEmit"
      :companyInfo="companyInfo"
    ></step-one>
    <step-two
      @toPrev="--step"
      v-show="step == 2"
      @submitEmit="submitEmit"
      :companyInfo="companyInfo"
    ></step-two>
    <step-three
      @toPrev="--step"
      v-show="step == 3"
      @submitEmit="submitEmit"
      :companyInfo="companyInfo"
    ></step-three>
    <div class="column-center success center" v-if="step == 4">
      <i class="iconfont lg primary">&#xe607;</i>
      <div class="title noraml">申请成功</div>
      <div class="mt10 muted nr">
        请等待管理员审核<br />（预计3个工作日内审核完成）
      </div>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
import StepOne from "./components/Step/StepOne";
import StepTwo from "./components/Step/StepTwo";
import StepThree from "./components/Step/StepThree.vue";
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
    "step-one": StepOne,
    "step-two": StepTwo,
    "step-three": StepThree,
  },
  data() {
    return {
      step: 0, //当前步骤
      submitForm: {}, //表单内容
      companyInfo: {},
      status: 0,
    };
  },
  watch: {},
  methods: {
    ...mapMutations("user", ["SET_USER_INFO"]),
    // 步骤进度
    submitEmit(data) {
      this.submitForm = { ...this.submitForm, ...data };
      if (this.step == 3) {
        this.$api.addCompanyQualificationApi(this.submitForm).then((res) => {
          if (res.code == 1) {
            this.step = 4;
            this.$store.state.isAudio = true;
            // this.$alert(
            //   `请在${
            //     this.config.aduit_day || 2
            //   }个工作日后登录查看（遇法定节顺延）`,
            //   "提交成功",
            //   {
            //     confirmButtonText: "确定",
            //     closeOnClickModal: false,
            //     closeOnPressEscape: false,
            //     closeOnHashChange: false,
            //     showClose: false,
            //     callback: (action) => {
            //       this.$store.dispatch("user/EXIT_LOGIN");
            //     },
            //   }
            // );
          }
        });
      } else {
        this.step++;
      }
    },
    getUserCenter() {
      this.$api.getUserCenterApi().then((res) => {
        if (res.code == 1) {
          let companyInfo = res.data?.company_info || null;
          if (companyInfo) {
            this.status = companyInfo.status;
            this.companyInfo = companyInfo;
            // 更新资质时带上ID
            this.submitForm.id = companyInfo.id;
            this.userInfo.qualification_status =
              this.status == 0 ? 1 : this.status == 1 ? 99 : 3;
            if (this.status == 1) {
              this.$router.replace({
                name: "CertificationInfo",
              });
            } else if (this.status == 0) {
              this.step = 4;
              this.$store.state.isAudio = true;
            } else {
              this.step = 1;
            }

            this.SET_USER_INFO(this.userInfo);
          }
        }
      });
    },
  },
  computed: {
    ...mapState(["config"]),
    ...mapGetters(["userInfo"]),
  },
  created() {
    // let type = this.$route.query.type || "";
    this.getUserCenter();
    // if (type == "error") {
    //   this.step = 1;
    // }
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.error-box {
  padding: 15px 20px;
  border-radius: 5px;
  border: 1px solid #f56c6c;
  background-color: #fef0f0;
  color: #f56c6c;
  line-height: 1.5;
}
.success {
  line-height: 1.5;
  padding-top: 30px;
  .iconfont {
    font-size: 130px;
  }
  .to-login {
    margin-top: 60px;
  }
  .title {
    font-size: 30px;
  }
}
.step-list {
  padding: 10px 53px 30px;
  margin-bottom: 30px;
  border-bottom: $-dashed-border;
  &::after,
  &::before {
    content: "";
    width: 8px;
    height: 14px;
    background-image: url(~@/assets/icon/icon-arrow5.png);
    background-size: cover;
    position: absolute;
    top: 54px;
    left: 287px;
  }
  &::before {
    left: auto;
    right: 268px;
  }
  .step-item {
    // img {
    //   width: 100px;
    //   height: 100px;
    //   margin-bottom: 20px;
    // }
    &.primary {
      .iconfont {
        color: $-color-primary;
      }
    }
    .iconfont {
      color: #ccc;
      font-size: 100px;
      margin-bottom: 20px;
    }
  }
}
.join {
  padding-top: 30px;
  p {
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  button {
    border-radius: 4px;
    width: 177px;
    height: 56px;
    cursor: pointer;
    margin-bottom: 150px;
  }
}
/deep/ .ruleForm {
  padding-top: 20px;
  width: 794px;
  .el-form-item__label {
    font-size: 18px;
    padding: 0 18px 0 0;
    color: $-color-lighter;
    &::before {
      display: none;
    }
  }
  .el-date-editor {
    width: 100%;
  }
  .el-radio-group {
    display: flex;
    align-items: center;
    .el-radio__label {
      font-size: 18px;
    }
    .el-radio__input {
      vertical-align: baseline;
      &.is-checked {
        .el-radio__inner {
          border-color: $-color-primary;
          background-color: $-color-primary;
        }
        & + .el-radio__label {
          color: $-color-primary;
        }
      }
    }
  }
  .el-input__icon,
  .el-radio-group,
  .el-form-item__label,
  .el-input__inner {
    height: 47px;
    line-height: 47px;
  }
  .region {
    .el-form-item__content {
      justify-content: space-between;
      display: flex;
      &::after,
      &::before {
        display: none;
      }
      .el-select {
        width: 195px;
      }
    }
  }
  button {
    width: 143px;
    height: 47px;
    margin-top: 20px;
    &.reset {
      background-color: $-color-reset;
      border-color: $-color-reset;
      &:hover {
        color: $-color-white;
      }
    }
  }
}
</style>
