var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('el-form',{ref:"ruleForm",staticClass:"ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"162px"}},[_c('el-form-item',{attrs:{"label":"营业执照副本","prop":"business_license_image"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"show-file-list":false,"action":`${_vm.download}/file/formimage`,"headers":{ token: _vm.$store.getters.userInfo.token },"on-success":(data) => {
            if(data.code==1){
              this.$message.success(data.msg);
            }else{
              this.$message.error(data.msg);
            }
        
            _vm.ruleForm.business_license_image = data.data.base_uri;
          },"before-upload":_vm.beforeAvatarUpload}},[(_vm.ruleForm.business_license_image)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.baseURL + _vm.ruleForm.business_license_image}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('div',{staticClass:"primary cursor",on:{"click":function($event){(_vm.pickerImg = '/static/shop/images/sl-yyzz.jpg'),
            (_vm.dialogVisible = true)}}},[_vm._v(" （示例图片） ")])],1),_c('el-form-item',{attrs:{"label":"身份证正面照","prop":"id_card_image"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"show-file-list":false,"action":`${_vm.download}/file/formimage`,"headers":{ token: _vm.$store.getters.userInfo.token },"on-success":(data) => {
            _vm.ruleForm.id_card_image = data.data.base_uri;
          },"before-upload":_vm.beforeAvatarUpload}},[(_vm.ruleForm.id_card_image)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.baseURL + _vm.ruleForm.id_card_image}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('div',{staticClass:"primary cursor",on:{"click":function($event){(_vm.pickerImg = '/uploads/images/20220211141654d2f107722.png'),
            (_vm.dialogVisible = true)}}},[_vm._v(" （示例图片） ")])],1),_c('el-form-item',{attrs:{"label":"许可证"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"show-file-list":false,"action":`${_vm.download}/file/formimage`,"headers":{ token: _vm.$store.getters.userInfo.token },"on-success":(data) => {
            _vm.ruleForm.license_image = data.data.base_uri;
          },"before-upload":_vm.beforeAvatarUpload}},[(_vm.ruleForm.license_image)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.baseURL + _vm.ruleForm.license_image}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('div',{staticClass:"primary cursor",on:{"click":function($event){(_vm.pickerImg = '/static/shop/images/sl-khxkz.jpg'),
            (_vm.dialogVisible = true)}}},[_vm._v(" （示例图片） ")])],1),_c('el-form-item',{attrs:{"label":"一般纳税人证","prop":"taxpayer_image"}},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"show-file-list":false,"action":`${_vm.download}/file/formimage`,"headers":{ token: _vm.$store.getters.userInfo.token },"on-success":(data) => {
            _vm.ruleForm.taxpayer_image = data.data.base_uri;
          },"before-upload":_vm.beforeAvatarUpload}},[(_vm.ruleForm.taxpayer_image)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.baseURL + _vm.ruleForm.taxpayer_image}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('div',{staticClass:"primary cursor",on:{"click":function($event){(_vm.pickerImg = '/static/shop/images/sl-ybnsrz.jpg'),
            (_vm.dialogVisible = true)}}},[_vm._v(" （示例图片） ")])],1),_c('el-form-item',[_c('el-button',{staticClass:"white md bg-primary",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v(" 提交审核 ")]),_c('el-button',{staticClass:"reset bg-center white md",on:{"click":function($event){return _vm.$emit('toPrev')}}},[_vm._v(" 上一步 ")]),_c('el-button',{staticClass:"reset bg-center white md",on:{"click":function($event){return _vm.resetForm('ruleForm')}}},[_vm._v("重置")])],1)],1),_c('el-dialog',{attrs:{"title":"示例图片","visible":_vm.dialogVisible,"width":"40%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.baseURL + _vm.pickerImg,"alt":""}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }