<template>
  <div class="">
    <!-- <center-title title="证件照片" :border="false"></center-title> -->
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="162px"
      class="ruleForm"
    >
      <el-form-item label="营业执照副本" prop="business_license_image">
        <el-upload
          :show-file-list="false"
          class="avatar-uploader"
          :action="`${download}/file/formimage`"
          :headers="{ token: $store.getters.userInfo.token }"
          :on-success="
            (data) => {
              if(data.code==1){
                this.$message.success(data.msg);
              }else{
                this.$message.error(data.msg);
              }
          
              ruleForm.business_license_image = data.data.base_uri;
            }
          "     
           :before-upload="beforeAvatarUpload"    
        
        >
          <img
            v-if="ruleForm.business_license_image"
            :src="baseURL + ruleForm.business_license_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div
          class="primary cursor"
          @click="
            (pickerImg = '/static/shop/images/sl-yyzz.jpg'),
              (dialogVisible = true)
          "
        >
          （示例图片）
        </div>
      </el-form-item>
      <el-form-item label="身份证正面照" prop="id_card_image">
        <el-upload
          :show-file-list="false"
          class="avatar-uploader"
          :action="`${download}/file/formimage`"
          :headers="{ token: $store.getters.userInfo.token }"
          :on-success="
            (data) => {
              ruleForm.id_card_image = data.data.base_uri;
            }
          "
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="ruleForm.id_card_image"
            :src="baseURL + ruleForm.id_card_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div
          class="primary cursor"
          @click="
            (pickerImg = '/uploads/images/20220211141654d2f107722.png'),
              (dialogVisible = true)
          "
        >
          （示例图片）
        </div>
      </el-form-item>
      <!-- prop="license_image" 取消必填 -->
      <el-form-item label="许可证">
        <el-upload
          :show-file-list="false"
          class="avatar-uploader"
          :action="`${download}/file/formimage`"
          :headers="{ token: $store.getters.userInfo.token }"
          :on-success="
            (data) => {
              ruleForm.license_image = data.data.base_uri;
            }
          "
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="ruleForm.license_image"
            :src="baseURL + ruleForm.license_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div
          class="primary cursor"
          @click="
            (pickerImg = '/static/shop/images/sl-khxkz.jpg'),
              (dialogVisible = true)
          "
        >
          （示例图片）
        </div>
      </el-form-item>
      <el-form-item label="一般纳税人证" prop="taxpayer_image">
        <el-upload
          :show-file-list="false"
          class="avatar-uploader"
          :action="`${download}/file/formimage`"
          :headers="{ token: $store.getters.userInfo.token }"
          :on-success="
            (data) => {
              ruleForm.taxpayer_image = data.data.base_uri;
            }
          "
          :before-upload="beforeAvatarUpload"
        >
          <img
            v-if="ruleForm.taxpayer_image"
            :src="baseURL + ruleForm.taxpayer_image"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div
          class="primary cursor"
          @click="
            (pickerImg = '/static/shop/images/sl-ybnsrz.jpg'),
              (dialogVisible = true)
          "
        >
          （示例图片）
        </div>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          class="white md bg-primary"
          @click="submitForm('ruleForm')"
        >
          提交审核
        </el-button>
        <el-button class="reset bg-center white md" @click="$emit('toPrev')">
          上一步
        </el-button>
        <el-button
          class="reset bg-center white md"
          @click="resetForm('ruleForm')"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog
      title="示例图片"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <img :src="baseURL + pickerImg" alt="" style="width: 100%" />
    </el-dialog>
  </div>
</template>
<script>
import CenterTitle from "../../../components/CenterTitle";
import { vxRule } from "@/utils/validator";
export default {
  name: "",
  props: {
    companyInfo: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    "center-title": CenterTitle,
  },
  watch: {
    companyInfo: {
      handler(val) {
        for (const key in this.ruleForm) {
          this.ruleForm[key] = val[key];
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      pickerImg: "",
      ruleForm: {
        id_card_image: "", //身份证正面照
        business_license_image: "", //营业执照副本
        license_image: "", //许可证
        taxpayer_image: "", //一般纳税人证
      },
      rules: {
        id_card_image: vxRule(true, null, "blur", "身份证正面照"),
        business_license_image: vxRule(
          true,
          null,
          "blur",
          "请上传营业执照副本"
        ),
        license_image: vxRule(true, null, "blur", "请上传许可证"),
        taxpayer_image: vxRule(true, null, "blur", "请上传一般纳税人证"),
      },
      download: process.env.VUE_APP_DOWNLOAD_URL,
      baseURL: process.env.VUE_APP_BASE_URL,
    };
  },
  computed: {},
  methods: {
    beforeAvatarUpload(file) {
        const allowedTypes = ['image/jpeg', 'image/png']; // 允许的图片类型列表  
        const isAllowedType = allowedTypes.includes(file.type);  
        const isLt2M = file.size / 1024 / 1024 < 2; // 图片大小不超过 2MB    
        if (!isAllowedType) {           
          this.$message.error('上传图片只能是 JPG 或 PNG 格式!');  
        }     
        if (!isLt2M) {  
          this.$message.error('上传图片大小不能超过 2MB!');  
        }       
        // 只有当图片类型和大小都满足条件时，才返回 true  
        return isAllowedType && isLt2M;  
    },
    handleClose(done) {
      done();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitEmit", this.ruleForm);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item {
  margin-bottom: 30px;
}
/deep/ .avatar-uploader {
  height: 202px;
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    img {
      height: 202px;
      width: 202px;
      object-fit: cover;
    }
    &:hover {
      border-color: $-color-primary;
    }
  }
  .avatar-uploader-icon {
    font-size: 60px;
    color: $-color-muted;
    width: 200px;
    height: 200px;
    line-height: 200px;
    text-align: center;
  }
  .avatar {
    width: 200px;
    height: auto;
    display: block;
  }
}
</style>
